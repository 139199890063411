import React from "react"
import { connectRefinementList } from 'react-instantsearch-dom';
import TitleCase from "utils/TitleCase";
import Label from "utils/Label";
import GetCourseTypes from "utils/GetCourseTypes"

const ButtonFilters = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  title,
  attribute
}) => {
  const handleFilterClick = (e, value) => {
    e.preventDefault();
    refine(value)
  }
  return (
    <div className="">
    <Label className="!text-orange mb-4">{title}</Label>
    <div className="flex flex-row flex-wrap lg:border-b border-grey lg:pb-6 gap-1.5">
      {items.map((item) => (
        <a
          className={`flex flex-row justify-center items-center font-condensed uppercase font-bold py-3 px-4 text-sm !leading-snug self-start tracking-[0.5px] hover:bg-darkorange hover:text-black duration-300 ${item.isRefined ? 'bg-orange text-black' : 'bg-[#363636] text-white'}`}
          key={item.label}
          href={createURL(item.value)}
          onClick={(e) => { handleFilterClick(e, item.value)}}
        >
          {attribute === 'type' ?
            GetCourseTypes(item.label) :
            TitleCase(item.label.replace(/-/g, ' ').replace(/_/g, ' '))
          }
        </a>
      ))}
    </div>
  </div>
)};

export default connectRefinementList(ButtonFilters);
