import React from "react";
import CourseFinder from "components/ES/Search/CourseFinder";
import Layout from "components/Layout";
import { PageContext } from "utils/context";
import Seo from "src/utils/seo"

const CourseFinderPage = ({ location }) => {
  const searchIndices = [{ name: `Courses`, title: `Courses` }];
  return (
    <PageContext.Provider
      value={{
        breadcrumb: '',
        location: location,
      }}
    >
      <Seo pageName={'Course Finder'} pageURL={'course-finder'}/>      
      <Layout location={location}>
          <CourseFinder indices={searchIndices} location={location}/>
      </Layout>
    </PageContext.Provider>
  );
};

export default CourseFinderPage;